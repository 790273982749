import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { useLocation, Link } from 'react-router-dom';
import { Button } from './catalyst/button';
import { InteractionStatus } from "@azure/msal-browser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxes, faCog, faPlay } from '@fortawesome/free-solid-svg-icons';
import { useFeatureFlagEnabled } from 'posthog-js/react'

import '../styles/App.css';

export const NavigationBar = () => {
    const { instance, inProgress } = useMsal();
    const isMacrosEnabled = useFeatureFlagEnabled("macros");

    let activeAccount = instance.getActiveAccount();
    const location = useLocation();
    const isLoginScreen = location.pathname === "/";
    const isERDashboard = location.pathname === "/er-dashboard";

    const isLoading = inProgress === InteractionStatus.Startup ||
        inProgress === InteractionStatus.HandleRedirect ||
        inProgress === InteractionStatus.Logout;

    const loadingMessage = inProgress === InteractionStatus.Logout ? "Securely Logging Out..." : "Securely Logging In...";



    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    const handleAppcuesFlow = () => {
        if (window.Appcues) {
            window.Appcues.show('c08456cb-f522-4fba-ab1c-0ca40ac656a2');
        } else {
            console.error('Appcues is not available.');
        }
    };

    const isCarepoint = useFeatureFlagEnabled('carepoint')


    return (
        <header className="transparent">
            <nav className="flex items-center justify-between pt-6 pr-6 pl-6 pb-1" aria-label="Global">
                {/* Logo on the left */}
                <Link to="/" className="-m-1.5 p-1.5">
                    <img className="h-10 w-24" src="/cleo-logo-expanded.png" alt="Cleo Logo" />
                </Link>

                {/* Authenticated/Unauthenticated Templates */}

                <div className="flex flex-1 justify-end gap-3">
                    <AuthenticatedTemplate>

                        {isERDashboard && window.Appcues && (
                            <button
                                onClick={handleAppcuesFlow}
                                className="inline-flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium text-indigo-900 bg-white border-2 border-indigo-900 hover:bg-indigo-50 hover:shadow-md transition-colors"
                            >
                                <FontAwesomeIcon icon={faPlay} className="h-4 w-4 mr-2" />
                                Pro Tips
                            </button>
                        )}

                        {activeAccount && (
                            <>
                                <a href="/settings" className="inline-flex items-center justify-center rounded-full transition-shadow hover:shadow-md p-2">
                                    <FontAwesomeIcon icon={faCog} className="h-6 w-6" />
                                    <span className="ml-2">Settings</span>
                                </a>
                            </>
                        )}

                    </AuthenticatedTemplate>
                    <AuthenticatedTemplate>
                        {activeAccount && (
                            <button onClick={handleLogoutRedirect} color="indigo-900" className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900">
                                Sign out
                            </button>
                        )}
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        {!isLoginScreen && !activeAccount && (
                            <button onClick={handleLoginRedirect} color="indigo-900" className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900">
                                Sign In
                            </button>
                        )}
                    </UnauthenticatedTemplate>
                </div>
            </nav>
            {isLoading && (
                <div className="loadingOverlay">
                    <div className="spinner"></div>
                    <div className="loadingMessage">{loadingMessage}</div>
                </div>
            )}
        </header>
    );
};
